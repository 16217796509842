<template>  
  <v-container style="max-width: 600px;">  
    <v-overlay v-model="showProgress" class="justify-center align-center">
      <v-progress-circular
        indeterminate
        size="60"
        width="5"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <ErrorPage v-if="showErrorPage" @OK="showErrorPage = false" />
    <v-row>  
      <v-col cols="6">  
        <v-card class="pa-4">  
          <h4 style="color: blue;">Myself*</h4>  
          <v-radio-group v-model="myGender" row :rules="[v => !!v || 'Gender is required']"  >  
            <v-radio :value="1" label="Male"></v-radio>  
            <v-radio :value="2" label="Female"></v-radio>  
          </v-radio-group>  
          <v-select  
            :rules="[v => !!v || 'Age is required']"
            v-model="myAge"  
            :items="ageOptions"  
            label="My Age*"  
            outlined
            :style="{ 'margin-bottom': '-20px' }"
          ></v-select>  
        </v-card>  
      </v-col>  
      <v-col>  
        <v-card class="pa-4">  
          <h4 style="color: blue;">My Date*</h4>  
          <v-radio-group v-model="partnerGender" row :rules="[v => !!v || 'Gender is required']">  
            <v-radio :value="1" label="Male"></v-radio>  
            <v-radio :value="2" label="Female"></v-radio>  
          </v-radio-group>  
          <v-select  
            :rules="[v => !!v || 'Age is required']"
            v-model="partnerAge"  
            :items="ageOptions"  
            label="Date's Age*"  
            outlined
            :style="{ 'margin-bottom': '-20px' }"
          ></v-select>  
        </v-card>  
      </v-col>  
    </v-row>  
    <v-row>  
      <v-col>  
        <v-card class="pa-4">  
          <h4 style="color: blue;">My Situation</h4>  
          <v-select  
            :rules="[v => !!v || 'Location is required']"
            class="mt-2"
            v-model="activity"  
            :items="activityOptions"  
            label="Select Location*"  
            :style="{ 'margin-bottom': '-20px' }"
            outlined
          ></v-select>  
          <v-text-field  
            v-if="activity === 'Other'"  
            v-model="otherActivity"  
            label="Please enter a location"  
            outlined
            :style="{ 'margin-bottom': '-20px' }"
          ></v-text-field>  
          <v-checkbox  
            v-model="hasSubset"  
            label="Do you want to specify an activity related to your location?"  
          ></v-checkbox>  
          <v-text-field  
            v-if="hasSubset"  
            class="mb-5"
            v-model="subset"  
            label="Please enter an activity"  
            hint="For example, if location is 'restaurant', an activity could be 'waiting for a meal'"
            persistent-hint
            outlined
          ></v-text-field>  
          <v-select  
            :rules="[v => !!v || 'Relationship Status is required']"
            :style="{ 'margin-bottom': '-20px' }"
            v-model="relationshipStatus"  
            :items="relationshipStatusOptions"  
            label="Relationship Status*"  
            outlined
          ></v-select>  
        </v-card>  
      </v-col>  
    </v-row>  
    <v-row no-gutters justify="center">
      <v-btn dark :disabled="!myGender || !myAge || !partnerGender || !partnerAge || !activity || !relationshipStatus" rounded class="mb-6 mt-8" elevation="25"  color="black white--text" @click="genMsg">{{ btnLabel }}</v-btn>  
      <v-checkbox dark
        class="ml-6 mt-9"
        v-model="funny"
        label="Be Funny"
      ></v-checkbox>
    </v-row>
    <v-row v-if="botContentMsg" no-gutters align="center" justify="center">
      <v-textarea
        dark
        outlined
        label="AI generated content may be inappropriate... use at your own risk!"
        v-model="botContentMsg"
        rows="3"
        auto-grow
      ></v-textarea>
    </v-row>
    <v-card max-width="400" class="mx-auto mt-16">
      <v-system-bar color="black"> </v-system-bar>
      <v-app-bar dark color="deep-purple accent-4">
        <v-toolbar-title class="mx-auto">Our Mission</v-toolbar-title>
      </v-app-bar>
      <v-container class="mb-8">
        <v-row dense>
          <v-col cols="12">
            <v-card class="black" dark>
              <v-card-subtitle>
                Have you ever found yourself at a loss for words when talking to that special someone? 
                Or struggling to make a connection during a conversation?
                Look no further. Our solution can help.
              </v-card-subtitle>
              <v-card-subtitle>
                Our mission at WingMateAI.com is to help individuals create meaningful connections with their special 
                someone through our innovative web app. We understand that starting a conversation can be difficult, 
                which is why we have developed an AI-powered wingman to provide users with interesting and engaging 
                topics to discuss. Our goal is to make dating and socializing easier and more enjoyable for everyone. 
                We strive to create a community of individuals who can confidently connect with others, whether it's 
                for a first date or a long-term relationship.
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>  
</template>  
  
<script>  
import axios from 'axios';
import ErrorPage from "@/components/ErrorPage.vue"

export default {  
  components: {
    ErrorPage
  },
  data() {  
    return {  
      chatURL: process.env.VUE_APP_CHAT_URL,
      chatApiKey: process.env.VUE_APP_CHAT_APIKEY,
      showProgress: false,
      botContentMsg: "",
      myGender: 0,  
      partnerGender: 0,  
      myAge: null,  
      partnerAge: null,  
      activity: "",  
      otherActivity: '',  
      hasSubset: false,  
      subset: "",  
      relationshipStatus: "",  
      // ageOptions: [18,19,20,21,22,23,24,25,26,27,28,29],  
      ageOptions: ['18 to 20', '21 to 25', '26 to 30', '31 to 35', '36 to 40', '41 to 45', '46 to 50', '51 to 55', '56 to 60'],  
      activityOptions: ['Bowling Alley', 'Movie Theatre', 'Online Chat', 'Park', 'Picnic', 'Restaurant', 'School', 'Skating Rink', 'Shopping Center', 'Show Event', 'Vehicle', 'Zoo', 'Other'],  
      relationshipStatusOptions: [  
        'Meeting for the First Time',
        'On Our First Date',  
        'Dating for a Week',  
        'Dating for a Month',  
        'Dating for 6 Months',  
        'Dating for a Year',  
      ],  
      reqBody: {
        messages: [],
        temperature: 0.05,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 800,
        stop: null
      },
      funny: false,
      numOfBtnPressed: 0,
      promptMsg: "",
      showErrorPage: false,
      sarado: false,
      numOfConsecutive: 0
    };  
  },  
  mounted(){
    this.showProgress = true
    this.restoreFromLocal()
  },
  computed: {
    myGenderValue(){
      if(this.myGender === 1){
        return "Male"
      } else {
        return "Female"
      }
    },
    partnerGenderValue(){
      if(this.partnerGender === 1){
        return "Male"
      } else {
        return "Female"
      }
    },
    actualActivity(){
      if(this.activity === "Other" && this.otherActivity){
        return this.otherActivity
      } else  if(this.activity === "Other" && !this.otherActivity) {
        return "place"
      } else {
        return this.activity
      }
    },
    btnLabel(){
      if(this.numOfBtnPressed === 0){
        return "What Should I say?"
      } else {
        return "What else can I say?"
      }
    },
  },
  methods: {
    genMsg(){
      if(this.numOfConsecutive > 50){
        this.sarado=true
      }

      if(this.sarado){
        this.botContentMsg = "YOU HAVE BEEN LOCKED UP DUE TO SUSPICIOUS ACTIVITIES! PLEASE CONTACT US FOR INSTRUCTIONS."
        return;
      }
      this.numOfConsecutive += 1
      if(this.relationshipStatus === 'Meeting for the First Time'){
        if(this.funny){
          if(this.subset){
            // this.promptMsg = "I am " + this.myAge + " yr old " + this.myGenderValue + " trying to date a " + this.partnerAge + " yr old " + this.partnerGenderValue + ". If I were in a " + this.actualActivity + " " + this.subset + " and I am meeting someone new for the first time, what one sentence can I say that is hilariously funny to help introduce myself?"
            this.promptMsg = "I am a " + this.myGenderValue + " between the age of " + this.myAge + " trying to date a " + this.partnerGenderValue + " between the age of " + this.partnerAge + ". If I were in a " + this.actualActivity + " " + this.subset + " and I am meeting someone new for the first time, what one sentence can I say that is hilariously funny to help introduce myself?"
          } else {
            this.promptMsg = "I am a " + this.myGenderValue + " between the age of " + this.myAge + " trying to date a " + this.partnerGenderValue + " between the age of " + this.partnerAge + ". If I were in a " + this.actualActivity + " and I am meeting someone new for the first time, what one sentence can I say that is hilariously funny to help introduce myself?"       } 
        } else {
          if(this.subset){
            this.promptMsg = "I am a " + this.myGenderValue + " between the age of " + this.myAge + " trying to date a " + this.partnerGenderValue + " between the age of " + this.partnerAge + ". If I were in a " + this.actualActivity + " " + this.subset + " and I am meeting someone new for the first time, what one sentence can I say to help introduce myself as my pickup line?"
          } else {
              this.promptMsg = "I am a " + this.myGenderValue + " between the age of " + this.myAge + " trying to date a " + this.partnerGenderValue + " between the age of " + this.partnerAge + ". If I were in a " + this.actualActivity + " and I am meeting someone new for the first time, what one sentence can I say to help introduce myself as my pickup line?"
          } 
        }
      } else {
        if(this.funny){
          if(this.subset){
            this.promptMsg = "I am a " + this.myGenderValue + " between the age of " + this.myAge + " trying to date a " + this.partnerGenderValue + " between the age of " + this.partnerAge + ". If I were in a " + this.actualActivity + " " + this.subset + " and we are " + this.relationshipStatus + ", generate a one sentence hilarious joke about our situation."
          } else {
            this.promptMsg = "I am a " + this.myGenderValue + " between the age of " + this.myAge + " trying to date a " + this.partnerGenderValue + " between the age of " + this.partnerAge + ". If I were in a " + this.actualActivity + " and we are " + this.relationshipStatus + ", generate a one sentence hilarious joke about our situation."        } 
        } else {
          if(this.subset){
            this.promptMsg = "I am a " + this.myGenderValue + " between the age of " + this.myAge + " trying to date a " + this.partnerGenderValue + " between the age of " + this.partnerAge + ". If I were in a " + this.actualActivity + " " + this.subset + " and we are " + this.relationshipStatus + ", give me one sentence to say to keep my date entertained."
          } else {
              this.promptMsg = "I am a " + this.myGenderValue + " between the age of " + this.myAge + " trying to date a " + this.partnerGenderValue + " between the age of " + this.partnerAge + ". If I were in a " + this.actualActivity + " and we are " + this.relationshipStatus + ", give me one sentence to say to keep my date entertained."
          } 
        }
      }

      console.log(this.promptMsg)

      if(this.numOfBtnPressed === 0){
        this.genMsg1()
      } else {
        this.genMsgRest()
      }
    },
    async genMsg1(){
      this.numOfBtnPressed += 1

      this.showProgress = true

      this.reqBody.messages.push(
        {
          role: "system",
          content: "You are an expert in romantic relationships."
        },      
        {
          role: "user",
          content: this.promptMsg
        }
      )
      // console.log(this.reqBody.messages[this.reqBody.messages.length -1].content)
      // this.reqBody.messages.forEach(item => console.log(item.content))
      // console.log("******************   *********************")
      try {
        const response = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBody),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );
        this.botContentMsg = response.data.choices[0].message.content
        this.reqBody.messages.push({role: "assistant", content: this.botContentMsg})
        this.showProgress=false
        this.save2local()
      } catch (error) {
        this.save2local()
        this.showErrorPage=true
        this.showProgress = false
        console.log(error);
      }
        
    },
    async genMsgRest(){
      this.showProgress = true

      this.reqBody.messages.push(
        {
          role: "user",
          content: "Again"
        }
      )
      // this.reqBody.messages.forEach(item => console.log(item.content))
      // console.log("******************   ********************")

      try {
        const response = await axios.post(
          this.chatURL,
          JSON.stringify(this.reqBody),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': this.chatApiKey,
            },
          },
        );
        this.botContentMsg = response.data.choices[0].message.content
        this.reqBody.messages.push({role: "assistant", content: this.botContentMsg})
        this.showProgress=false
        this.save2local()
      } catch (error) {
        this.save2local()
        this.showErrorPage=true
        this.showProgress = false
        console.log(error);
      }
      // console.log(this.reqBody.messages)
    },
    save2local(){
      const params = {
        pSarado: this.sarado,
        pMyGender: this.myGender,
        pMyAge: this.myAge,
        pPartnerGender: this.partnerGender,
        pPartnerAge: this.partnerAge,
        pActivity: this.activity,
        pOtherActivity: this.otherActivity,
        pHasSubset: this.hasSubset,
        pSubset: this.subset,
        pRelationshipStatus: this.relationshipStatus,
        pNumOfBtnPressed: this.numOfBtnPressed,
        pNumOfConsecutive: this.numOfConsecutive,
        pBotContentMsg: this.botContentMsg
      }
      localStorage.setItem('iabmw', JSON.stringify(params))
    },
    restoreFromLocal(){
      this.showProgress = true
      const paramsUsed = JSON.parse(localStorage.getItem('iabmw'))

      // if there's something stored locally
      if (paramsUsed){
        this.sarado = paramsUsed.pSarado
        this.myGender = paramsUsed.pMyGender
        this.myAge = paramsUsed.pMyAge
        this.partnerGender = paramsUsed.pPartnerGender
        this.partnerAge = paramsUsed.pPartnerAge
        this.activity = paramsUsed.pActivity
        this.otherActivity = paramsUsed.pOtherActivity
        this.hasSubset = paramsUsed.pHasSubset
        this.subset = paramsUsed.pSubset
        this.relationshipStatus = paramsUsed.pRelationshipStatus
        this.numOfBtnPressed = paramsUsed.pNumOfBtnPressed
        this.numOfConsecutive = paramsUsed.pNumOfConsecutive
        this.botContentMsg = paramsUsed.pBotContentMsg
      } 
      this.showProgress = false
    }
  },
  watch: {
    hasSubset(){
      this.subset = ""
      this.numOfConsecutive = 0
    },
    activity(){
      this.numOfBtnPressed = 0
      this.otherActivity = ""
      this.reqBody.messages = []
      this.numOfConsecutive = 0
      this.hasSubset = false
      this.subset = ""
    },
    funny(){
      this.numOfBtnPressed = 0
      this.reqBody.messages = []
      this.numOfConsecutive = 0
    },
    myGender(){
      this.numOfBtnPressed = 0
      this.reqBody.messages = []
      this.numOfConsecutive = 0
    },
    partnerGender(){
      this.numOfBtnPressed = 0
      this.reqBody.messages = []
      this.numOfConsecutive = 0
    },
    myAge(){
      this.numOfBtnPressed = 0
      this.reqBody.messages = []
      this.numOfConsecutive = 0
    },
    partnerAge(){
      this.numOfBtnPressed = 0
      this.reqBody.messages = []
      this.numOfConsecutive = 0
    },
    otherActivity(){
      this.numOfBtnPressed = 0
      this.reqBody.messages = []
      this.numOfConsecutive = 0
    },
    subset(){
      this.numOfBtnPressed = 0
      this.reqBody.messages = []
      this.numOfConsecutive = 0
    },
    relationshipStatus(){
      this.numOfBtnPressed = 0
      this.reqBody.messages = []
      this.numOfConsecutive = 0
    }
  }
};  
</script>  
