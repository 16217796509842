<template>
  
    <v-app>
      <div class="gradient-background">
    <v-app-bar
      app
      color="black"
      dark
    >
    <v-toolbar-title @click="$router.push('/')">
      <span class="font-weight-light white--text" style="font-size: 25px;">WingMate</span>
        <span style="margin-left: 10px"></span>
        <span class="white--text" style="font-size: 15px">by</span>
        <span style="margin-left: 10px"></span>
        <span class="bold white--text" style="font-size: 25px">AI</span>
    </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="yellow"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link to="contactus">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </div>
  </v-app>


</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>  
.gradient-background {  
  background: linear-gradient(to bottom right, #020102, #7836ea);  
}  
</style>  